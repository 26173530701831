@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.homeLayoutContainer {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(var(--numberOfRows, 5), auto) 0px;
	grid-auto-flow: column;
	grid-template-areas:
		'MainContent'
		'SubContent'
		'MainContentBottom'
		'AdSidebar'
		'SideBar';

	@media #{map.get(v.$media, "tablet")} {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(var(--numberOfRows, 5), auto) 0px;
		grid-auto-flow: row;
		grid-gap: 0px 1%;
		grid-template-areas:
			'MainContent MainContent'
			'SubContent SubContent'
			'MainContentBottom MainContentBottom'
			'AdSidebar AdSidebar'
			'SideBar SideBar';
	}

	@media #{map.get(v.$media, "desktopS")} {
		grid-template-columns: 0fr 2fr 3fr 1fr 0fr;
		grid-template-rows: repeat(var(--numberOfRows, 5), auto) 0px;
		grid-gap: 0px 1%;
		grid-template-areas:
			'. AdSidebar AdSidebar AdSidebar .'
			'. SubContent MainContent SideBar .'
			'. MainContentBottom MainContentBottom SideBar .';
		margin-top: 12px;
	}

	@media #{map.get(v.$media, "desktopM")} {
		grid-template-columns: 1fr 336px 550px 300px 1fr;
	}
}

.mainContent {
	grid-area: MainContent;
}

.subContent {
	grid-area: SubContent;
}

.sidebarContent {
	grid-area: SideBar;
	display: none;

	@media #{map.get(v.$media, "desktopS")} {
		display: block;
	}
}

.mainContentBottom {
	display: grid;
	grid-area: MainContentBottom;

	@media #{map.get(v.$media, "desktopS")} {
		margin-top: 25px;
		grid-gap: 0 2%;
		grid-template-columns: 2fr 3fr;
	}

	@media #{map.get(v.$media, "desktopM")} {
		grid-template-columns: 336px 3fr;
	}
}
.bottom {
	grid-column: 1/3;
	grid-row: var(--gridRowStart, 4);

	@media #{map.get(v.$media, "desktopS")} {
		grid-column: 2/5;
		margin: 25px 0;
	}

	@media #{map.get(v.$media, "desktopM")} {
		grid-template-columns: 336px 3fr;
	}
}

.bleed {
	background-color: var(--lightBlue);
	display: grid;
	grid-column: 1/-1;

	@media #{map.get(v.$media, "desktopS")} {
		grid-template-columns: 0fr 2fr 3fr 1fr 0fr;
		grid-gap: 0px 1%;

		> :first-child {
			grid-column: 2/5;
		}
	}

	@media #{map.get(v.$media, "desktopM")} {
		grid-template-columns: 1fr 336px 550px 300px 1fr;
	}
}

.adContainer {
	grid-area: AdSidebar;

	@media #{map.get(v.$media, "desktopS")} {
		display: none;
	}
}

.locationSearchContainer {
	padding: 0 16px;
	margin-bottom: 12px;
	width: 100%;
}
