@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.ButtonBarContainer {
	display: flex;
	justify-content: space-evenly;
	gap: 3%;
	margin-top: 15px;
	padding: 0 16px;

	@media #{map.get(v.$media, "desktopS")} {
		margin-top: 30px;
	}
}

.ShortName {
	@media #{map.get(v.$media, "tablet")} {
		display: none;
	}
}
.Name {
	display: none;
	@media #{map.get(v.$media, "tablet")} {
		display: block;
	}
}
