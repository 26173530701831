@use "sass:map";
@use "styles/variables.module.scss" as v;

.weatherReportContainer {
  background-color: var(--lightBlue);
  padding: 20px 16px 10px;

  @media #{map.get(v.$media, "desktopS")} {
    padding: 20px 16px 10px 0;
  }

  h2 {
    padding: 0 0 10px 0;
    border-bottom: 1px solid var(--grey2);

    @media #{map.get(v.$media, "desktopS")} {
      padding: 0;
      border-bottom: none;
    }
  }
}

.weatherReport  {
  display: flex;
  margin: 18px 0;

  @media #{map.get(v.$media, "desktopS")} {
    width: 70%;
    margin: 0;
  }

  blockquote {
    margin: 0;
  }
}

.weatherReportCreator  {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--waterBlue);
}
